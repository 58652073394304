import { capitalise } from "@wisr/common";
import { Application } from "../../application/application";
import {
  PaymentFrequency,
  applicationFormDefaults,
} from "../../application/application-form.constants";
import { ID_TYPES } from "../../application/application.utils";
import { otherIncomeInitialList } from "../../application/multi-page-application-form/form-pages/other-income-list/other-income-list.constants";
import {
  CREDIT_CARD_VALUE,
  debtInitialList,
} from "../../application/multi-page-application-form/form-pages/your-debts/debt-list/debt-list.constants";
import {
  defaultIncomeSourceValue,
  initialDebtList,
  initialIdentityList,
  initialOtherIncomeList,
  significantChangeValues,
} from "./loan-application.constants";
import dayjs from "dayjs";
import { BooleanOrInvalid, StringOrInvalid } from "../../types/shared";

export const addSlashSpaces = (date: StringOrInvalid): string => {
  if (date) {
    return date.replace(/\//g, " / ");
  }

  return "";
};

export const valueToString = (
  value: string | number | boolean | undefined | null,
  defaultValue = ""
): string => {
  return value ? value.toString() : defaultValue;
};

export const valueToYesNo = (value: BooleanOrInvalid) => {
  return value ? "Yes" : "No";
};

export const setRadioBoolean = (value: BooleanOrInvalid) => {
  if (value === true) {
    return ["Yes"];
  }
  return [];
};

export const getLoanTermInYears = (loanTerm: string) => {
  return loanTerm ? `${+loanTerm / 12}` : "";
};

export const getNumberOfDependents = (
  dependents: number | null | undefined
) => {
  return dependents === 0 ? "0" : valueToString(dependents);
};

export const valueToYesNoBlank = (value: BooleanOrInvalid) => {
  return value == null ? "" : valueToYesNo(value);
};

export const getIdentificationDocuments = (
  identities: DashAPI.Identities | undefined
) => {
  if (!identities) return initialIdentityList;

  const identityList = { ...initialIdentityList };

  if (identities.driverLicence) {
    identityList["driverLicenceNumber"] = valueToString(
      identities.driverLicence.number
    );
    identityList["driverLicenceCardNumber"] = valueToString(
      identities.driverLicence.cardNumber
    );
    identityList["driversLicenceExpiry"] = valueToString(
      addSlashSpaces(identities.driverLicence.expiryDate)
    );
    identityList["driversLicenceState"] = valueToString(
      identities.driverLicence.state
    ).toLowerCase();

    const hasAllDriverLicenceFields =
      identities.driverLicence.number &&
      identities.driverLicence.cardNumber &&
      identities.driverLicence.expiryDate &&
      identities.driverLicence.state;

    if (hasAllDriverLicenceFields) {
      identityList["idenfiticationSource1"] = ID_TYPES.DRIVERS_LICENCE;
    }
  }

  if (identities.medicare) {
    identityList["medicareCardNumber"] = valueToString(
      identities.medicare.number
    );
    identityList["medicareColour"] = valueToString(
      identities.medicare.cardColour
    );
    identityList["medicareReferenceNumber"] = valueToString(
      identities.medicare.referenceNumber
    );
    identityList["medicareInitial"] = valueToString(
      identities.medicare.middleNameInitial
    ).substring(0, 1);
    identityList["medicareExpiry"] = valueToString(
      addSlashSpaces(identities.medicare.expiryDate)
    );

    const hasAllMedicareFields =
      identities.medicare.number &&
      identities.medicare.cardColour &&
      identities.medicare.referenceNumber &&
      identities.medicare.expiryDate;

    if (hasAllMedicareFields) {
      if (identityList.idenfiticationSource1) {
        identityList["idenfiticationSource2"] = ID_TYPES.MEDICARE;
        return identityList;
      } else {
        identityList["idenfiticationSource1"] = ID_TYPES.MEDICARE;
      }
    }
  }

  if (identities.passport) {
    identityList["passportNumber"] = valueToString(identities.passport.number);
    identityList["passportCountry"] = valueToString(
      identities.passport.country
    );

    const hasAllPassportFields =
      identities.passport.number && identities.passport.country;

    if (hasAllPassportFields) {
      if (identityList.idenfiticationSource1) {
        identityList["idenfiticationSource2"] = ID_TYPES.PASSPORT;
      } else {
        identityList["idenfiticationSource1"] = ID_TYPES.PASSPORT;
      }
    }
  }

  return identityList;
};

export const addMobileSpaces = (mobileNumber: string) => {
  if (mobileNumber.length < 7) {
    return mobileNumber;
  }

  return `${mobileNumber.substring(0, 4)} ${mobileNumber.substring(
    4,
    7
  )} ${mobileNumber.substring(7)}`;
};

export const getAddress = (
  addresses: DashAPI.Address[] | null | undefined,
  isPrimaryAddress: boolean
) => {
  if (addresses && addresses.length > 0) {
    return (
      addresses.find((address) => address.isPrimary === isPrimaryAddress) ?? {}
    );
  }

  return {};
};

export const mapToAddressUpdated = (
  address: DashAPI.Address | null | undefined
) => {
  if (address) {
    const streetAddress = address.unit
      ? `${address.unit}/${address.streetNumber}`
      : address.streetNumber;

    const [streetName, streetType, suburb, state, postcode] = [
      address.streetName,
      address.streetType,
      address.suburb,
      address.state,
      address.postcode,
    ].map((name) => valueToString(name));

    if (streetName && suburb && state) {
      return `${streetAddress} ${streetName} ${capitalise(
        streetType
      )}, ${suburb} ${state.toUpperCase()} ${postcode}`.replace(" ,", ",");
    }

    return "";
  }

  return "";
};

const getEmployment = (
  employments: DashAPI.Employment[] | null | undefined,
  isCurrentEmployment: boolean
) => {
  if (employments && employments.length > 0) {
    return (
      employments.find(
        (employment) => employment.isCurrent === isCurrentEmployment
      ) ?? {}
    );
  }

  return {};
};

export const getYearsAndMonths = (
  startDate: StringOrInvalid,
  endDate?: StringOrInvalid
) => {
  const dateRegex = /\d{2}\/\d{4}$/;

  if (
    !startDate ||
    !startDate.match(dateRegex) ||
    (endDate && !endDate.match(dateRegex))
  ) {
    return {
      years: "",
      months: "",
    };
  }

  if (endDate) {
    const totalMonthsFromEnd = dayjs(endDate, "MM/YYYY").diff(
      dayjs(startDate, "MM/YYYY"),
      "months"
    );
    return {
      years: `${Math.floor(totalMonthsFromEnd / 12)}`,
      months: `${totalMonthsFromEnd % 12}`,
    };
  }

  const totalMonths = dayjs().diff(dayjs(startDate, "MM/YYYY"), "months");
  return {
    years: `${Math.floor(totalMonths / 12)}`,
    months: `${totalMonths % 12}`,
  };
};

export const getAddressLengths = (
  addresses: DashAPI.Address[] | null | undefined
) => {
  const primaryAddress = getAddress(addresses, true);
  const secondaryAddress = getAddress(addresses, false);

  const primaryAddressLength = getYearsAndMonths(primaryAddress.startDate);

  const secondaryAddressLength = getYearsAndMonths(
    secondaryAddress.startDate,
    secondaryAddress.endDate
  );

  return {
    primaryAddressLength,
    secondaryAddressLength,
  };
};

export const getEmploymentLengths = (
  employments: DashAPI.Employment[] | null | undefined
) => {
  const currentEmployment = getEmployment(employments, true);
  const previousEmployment = getEmployment(employments, false);

  const currentEmploymentLength = getYearsAndMonths(
    currentEmployment.startDate
  );

  const previousEmploymentLength = getYearsAndMonths(
    previousEmployment.startDate,
    previousEmployment.endDate
  );

  return {
    currentEmploymentLength,
    previousEmploymentLength,
  };
};

const getOtherIncomes = (
  otherIncomes: DashAPI.OtherIncome[] | null | undefined
) => {
  if (otherIncomes) {
    const otherIncomeList = {
      ...initialOtherIncomeList,
    };
    otherIncomeList["hasOtherIncomes"] = "yes";
    otherIncomeInitialList.forEach((otherIncomeKey, i) => {
      if (otherIncomes[i]) {
        otherIncomeList[otherIncomeKey.amount] = valueToString(
          otherIncomes[i].amount
        );
        otherIncomeList[otherIncomeKey.frequency] = valueToString(
          otherIncomes[i].frequency
        );
        otherIncomeList[otherIncomeKey.source] = valueToString(
          otherIncomes[i].source
        );
      }
    });

    return otherIncomeList;
  }

  return initialOtherIncomeList;
};

const getDebts = (debts: DashAPI.Debt[] | null | undefined) => {
  if (debts && debts.length > 0) {
    const debtList = { ...initialDebtList };
    debtList["hasDebts"] = "yes";
    debtInitialList.forEach((debtKey, i) => {
      if (debts[i]) {
        debtList[debtKey.type] = valueToString(debts[i].debtType);
        debtList[debtKey.financeCompany] = valueToString(
          debts[i].financialCompanyName
        );
        debtList[debtKey.balance] = valueToString(debts[i].balance);
        debtList[debtKey.repaymentAmount] = valueToString(debts[i].repayment);
        debtList[debtKey.repaymentFrequency] = valueToString(
          debts[i].frequency
        );
        debtList[debtKey.isJointLoan] = setRadioBoolean(debts[i].isJointLoan);
        debtList[debtKey.consolidate] = setRadioBoolean(
          debts[i].isForConsolidation
        );
        debtList[debtKey.isInvestment] = setRadioBoolean(
          debts[i].isInvestmentProperty
        );
        debtList[debtKey.creditCardLimit] = valueToString(debts[i].limit);
        debtList[debtKey.closeThisCard] = setRadioBoolean(
          debts[i].accountToBeClosed
        );
      }
    });

    return debtList;
  }

  return initialDebtList;
};

const getSignificantChangesTypes = (
  significantChanges:
    | DashAPI.ApplicantSignificantPlannedChanges
    | null
    | undefined
): string[] => {
  if (significantChanges) {
    return Object.entries(significantChanges)
      .map((change) => {
        if (change[1] === true) {
          const significantChangeMatch = significantChangeValues.find(
            (significantChangeValue) =>
              significantChangeValue.value === change[0]
          );
          if (significantChangeMatch) return significantChangeMatch.formValue;
        }
      })
      .reduce((reducedValues: string[], value) => {
        if (value !== undefined) {
          reducedValues.push(value);
        }
        return reducedValues;
      }, []);
  }

  return [] as string[];
};

export const mapToLoanApplicationForm = (
  response: DashAPI.LoanApplicationModel
): Application => {
  if (response.applicants && response.applicants[0]) {
    const applicant = response.applicants[0];
    const identities = getIdentificationDocuments(applicant.identities);
    const primaryAddress = getAddress(applicant.addresses, true);
    const secondaryAddress = getAddress(applicant.addresses, false);
    const addressLengths = getAddressLengths(applicant.addresses);
    const currentEmployment = getEmployment(applicant.employments, true);
    const previousEmployment = getEmployment(applicant.employments, false);
    const employmentLengths = getEmploymentLengths(applicant.employments);
    const otherIncomes = getOtherIncomes(
      applicant.financialDetails?.otherIncomes
    );
    const expenses = applicant.financialDetails?.expenses;
    const debts = getDebts(applicant.financialDetails?.debts);
    const significantChanges = getSignificantChangesTypes(
      applicant.significantPlannedChanges
    );

    return {
      userType: "",
      requirementsAndObjectives: valueToString(
        response.loanRequirementsAndObjectives
      ),
      loanId: valueToString(response.loanId),
      loanPurpose: valueToString(response.loanPurpose),
      loanTerm: getLoanTermInYears(valueToString(response.loanTerm)),
      loanAmount: valueToString(response.loanAmount),
      title: valueToString(applicant.title),
      firstName: valueToString(applicant.firstName),
      middleName: valueToString(applicant.middleName),
      lastName: valueToString(applicant.lastName),
      dateOfBirth: addSlashSpaces(valueToString(applicant.doB)),
      maritalStatus: valueToString(applicant.householdDetails?.maritalStatus),
      numberOfFinancialDependents: getNumberOfDependents(
        applicant.householdDetails?.dependents
      ),

      ...identities,

      email: valueToString(applicant.contact?.email),
      mobile: addMobileSpaces(valueToString(applicant.contact?.mobile)),

      address: mapToAddressUpdated(primaryAddress),
      addressUnit: valueToString(primaryAddress.unit),
      addressStreetNumber: valueToString(primaryAddress.streetNumber),
      addressStreetName: valueToString(primaryAddress.streetName),
      addressStreetType: valueToString(primaryAddress.streetType),
      addressSuburb: valueToString(primaryAddress.suburb),
      addressState: valueToString(primaryAddress.state),
      addressPostcode: valueToString(primaryAddress.postcode),
      yearsAtAddress: addressLengths.primaryAddressLength.years,
      monthsAtAddress: addressLengths.primaryAddressLength.months,
      livingSituationAtAddress: valueToString(primaryAddress.residencyStatus),

      mortgageFinanceCompany: valueToString(
        primaryAddress.mortgageFinanceCompanyName
      ),
      mortgageBalance: valueToString(primaryAddress.mortgageBalance),
      mortgageRepaymentAmount: valueToString(
        primaryAddress.mortgageRepaymentAmount
      ),
      mortgageRepaymentFrequency: valueToString(
        primaryAddress.mortgageFrequency
      ),
      hasCoBorrower: valueToYesNoBlank(primaryAddress.isJointMortgage),

      totalRentalAmount: valueToString(primaryAddress.totalRentalAmount, "0"),
      rentalAmountFrequency: valueToString(
        primaryAddress.frequency,
        PaymentFrequency.MONTHLY
      ),
      rentShared: primaryAddress.totalRentAmountShared
        ? ["Total rental amount is shared?"]
        : [],
      propertyOwnerManagerName: valueToString(
        primaryAddress.propertyContactName
      ),
      propertyOwnerManagerPhone: addMobileSpaces(
        valueToString(primaryAddress.propertyContactNumber)
      ),

      previousAddress: mapToAddressUpdated(secondaryAddress),
      previousAddressUnit: valueToString(secondaryAddress.unit),
      previousAddressStreetNumber: valueToString(secondaryAddress.streetNumber),
      previousAddressStreetName: valueToString(secondaryAddress.streetName),
      previousAddressStreetType: valueToString(secondaryAddress.streetType),
      previousAddressSuburb: valueToString(secondaryAddress.suburb),
      previousAddressState: valueToString(secondaryAddress.state),
      previousAddressPostcode: valueToString(secondaryAddress.postcode),
      yearsAtPreviousAddress: addressLengths.secondaryAddressLength.years,
      monthsAtPreviousAddress: addressLengths.secondaryAddressLength.months,
      livingSituationAtPreviousAddress: valueToString(
        secondaryAddress.residencyStatus
      ),

      employmentStatus: valueToString(currentEmployment.employmentStatus),
      yearsAtEmployer: valueToString(
        employmentLengths.currentEmploymentLength.years
      ),
      monthsAtEmployer: valueToString(
        employmentLengths.currentEmploymentLength.months
      ),
      employerName: valueToString(currentEmployment.employerName),
      occupation: valueToString(currentEmployment.occupation),
      employmentContactNumber: addMobileSpaces(
        valueToString(currentEmployment.employerMobile)
      ),
      employmentIndustry: valueToString(currentEmployment.industry),

      previousEmploymentStatus: valueToString(
        previousEmployment.employmentStatus
      ),
      yearsAtPreviousEmployer: valueToString(
        employmentLengths.previousEmploymentLength.years
      ),
      monthsAtPreviousEmployer: valueToString(
        employmentLengths.previousEmploymentLength.months
      ),
      previousEmployerName: valueToString(previousEmployment.employerName),
      previousOccupation: valueToString(previousEmployment.occupation),
      previousEmploymentContactNumber: addMobileSpaces(
        valueToString(previousEmployment.employerMobile)
      ),

      incomeSource: defaultIncomeSourceValue,
      incomeAmount: valueToString(applicant.totalIncomeBeforeTax),
      incomeFrequency: valueToString(
        currentEmployment.incomeFrequency,
        "annual"
      ),

      ...otherIncomes,

      partnerIncomeAmount: valueToString(
        applicant.financialDetails?.partnerTotalAnnualIncome
      ),

      groceries: valueToString(expenses?.groceries, "0"),
      clothing: valueToString(expenses?.clothing, "0"),
      council_rates: valueToString(expenses?.councilRates, "0"),
      car_registration: valueToString(
        expenses?.carAndMotorbikeRegistration,
        "0"
      ),
      petrol: valueToString(expenses?.petrolAndMaintenance, "0"),
      health_insurance: valueToString(expenses?.healthInsurance, "0"),
      car_home_insurance: valueToString(expenses?.carAndHomeInsurance, "0"),
      utilities: valueToString(expenses?.utilities, "0"),
      communication: valueToString(expenses?.communications, "0"),
      transportation: valueToString(expenses?.transportation, "0"),
      education: valueToString(expenses?.privateEducation, "0"),
      entertainment: valueToString(expenses?.entertainment, "0"),
      other_general_expenses: valueToString(expenses?.other, "0"),
      other_additional_expenses: "0",
      seconds_motor_vehicle: valueToString(expenses?.secondMotorVehicle, "0"),

      ...debts,

      skipCreditSense: [] as string[],
      creditSenseResult: "",
      allowBypassCreditSense: valueToString(response.allowBypassCreditSense),

      assetFound: valueToYesNo(response.securedAssetDetails?.isKnown),
      assetYear: valueToString(response.securedAssetDetails?.yearOfManufacture),
      assetSeller: valueToString(response.securedAssetDetails?.saleType),
      assetAgeEstimate: valueToString(
        response.securedAssetDetails?.approximateAge,
        "0"
      ),

      ownsInvestmentProperty: valueToYesNo(
        applicant.investmentProperty?.ownsInvestmentProperty
      ),
      investmentPropertyMortgaged: valueToYesNo(
        applicant.investmentProperty?.investmentPropertyMortgaged
      ),

      bankName: "",
      accountName: "",
      bsb: "",
      accountNumber: "",
      jointAccount: [] as string[],
      jointAccountName: "",

      significantPlannedChanges: significantChanges.length > 0 ? "Yes" : "",
      significantPlannedChangeTypes: significantChanges,

      termsAndConditionsAgreement: [] as string[],

      requestSecuredRate: valueToString(response.requestSecuredRate),
      page: response.page ?? "0",
      offerId: response.offerId ?? "",
      partnerCompanyId: response.partnerCompanyId ?? "",
    };
  }

  return applicationFormDefaults;
};

export const isValueYes = (value: string) => {
  return !!value && value.toLowerCase() === "yes";
};

const isValueTrue = (value: string) => {
  return value.toLowerCase() === "true";
};

const getStartAndEndDates = (application: Application, type: string) => {
  const isEmployment = type === "employment";

  const timeAt = isEmployment
    ? {
        yearsAtCurrent: application.yearsAtEmployer,
        monthsAtCurrent: application.monthsAtEmployer,
        yearsAtPrevious: application.yearsAtPreviousEmployer,
        monthsAtPrevious: application.monthsAtPreviousEmployer,
      }
    : {
        yearsAtCurrent: application.yearsAtAddress,
        monthsAtCurrent: application.monthsAtAddress,
        yearsAtPrevious: application.yearsAtPreviousAddress,
        monthsAtPrevious: application.monthsAtPreviousAddress,
      };

  const currentStartDate = dayjs()
    .subtract(+timeAt.yearsAtCurrent, "years")
    .subtract(+timeAt.monthsAtCurrent, "months")
    .format("MM/YYYY");

  const previousStartDate = dayjs()
    .subtract(+timeAt.yearsAtCurrent + +timeAt.yearsAtPrevious, "years")
    .subtract(+timeAt.monthsAtCurrent + +timeAt.monthsAtPrevious, "months")
    .format("MM/YYYY");
  return {
    currentStartDate,
    previousStartDate,
  };
};

const hasPreviousEmployer = (application: Application) => {
  return (
    +application.yearsAtEmployer === 0 && +application.monthsAtEmployer < 6
  );
};

const hasDriverLicence = (application: Application) => {
  return (
    !!application.driverLicenceNumber &&
    !!application.driverLicenceCardNumber &&
    !!application.driversLicenceExpiry &&
    !!application.driversLicenceState
  );
};

const hasMedicare = (application: Application) => {
  return (
    !!application.medicareCardNumber &&
    !!application.medicareColour &&
    !!application.medicareReferenceNumber &&
    !!application.medicareExpiry
  );
};

const hasPassport = (application: Application) => {
  return !!application.passportNumber && !!application.passportCountry;
};

const hasPartner = (maritalStatus: string) => {
  return ["married", "de-facto"].includes(maritalStatus);
};

export const getStringOrNull = (value: string) => {
  return value ? value : null;
};

const hasSecondaryAddress = (application: Application) => {
  return (
    !!application.previousAddressStreetNumber &&
    !!application.previousAddressStreetName &&
    !!application.previousAddressSuburb
  );
};

const getOtherIncomesFromForm = (
  application: Application
): DashAPI.OtherIncome[] => {
  const otherIncomesSet = otherIncomeInitialList.filter((otherIncomeKey) => {
    if (
      application[otherIncomeKey.source] &&
      application[otherIncomeKey.amount] &&
      application[otherIncomeKey.frequency]
    ) {
      return otherIncomeKey;
    }
  });

  return otherIncomesSet.map((otherIncome) => {
    return {
      source: application[otherIncome.source],
      amount: trimDecimals(+application[otherIncome.amount]),
      frequency: application[otherIncome.frequency],
    } as DashAPI.OtherIncome;
  });
};

export const isRadioYes = (value: string[]) => {
  if (value.length > 0 && value[0].toLowerCase() !== "no") {
    return true;
  }
  return false;
};

const getDebtsFromForm = (application: Application): DashAPI.Debt[] => {
  if (!isValueYes(application.hasDebts)) {
    return [];
  }

  const debtsSet = debtInitialList.filter((debtKey) => {
    if (application[debtKey.type] && application[debtKey.financeCompany]) {
      const debtType = application[debtKey.type];
      const hasCreditCardFields =
        debtType === CREDIT_CARD_VALUE && application[debtKey.creditCardLimit];
      const hasOtherDebtFields =
        application[debtKey.balance] &&
        application[debtKey.repaymentAmount] &&
        application[debtKey.repaymentFrequency];
      if (hasCreditCardFields || hasOtherDebtFields) {
        return debtKey;
      }
    }
  });

  return debtsSet.map((debt) => {
    return {
      debtType: application[debt.type],
      financialCompanyName: application[debt.financeCompany],
      repayment: trimDecimals(+application[debt.repaymentAmount]),
      limit: trimDecimals(+application[debt.creditCardLimit]),
      balance: trimDecimals(+application[debt.balance]),
      frequency: application[debt.repaymentFrequency],
      isJointLoan: isRadioYes(application[debt.isJointLoan]),
      isForConsolidation: isRadioYes(application[debt.consolidate]),
      accountToBeClosed: isRadioYes(application[debt.closeThisCard]),
      isInvestmentProperty: isRadioYes(application[debt.isInvestment]),
    } as DashAPI.Debt;
  });
};

const getSignificantChangesFromForm = (
  application: Application
): DashAPI.ApplicantSignificantPlannedChanges => {
  const significantChanges = application.significantPlannedChangeTypes;
  const hasSignificantChanges = significantChanges.length > 0;

  return {
    hasSignificantPlannedChanges: hasSignificantChanges,
    expenseIncreaseShortTerm: significantChanges.includes(
      "short_term_increase_in_expenses"
    ),
    expenseIncreaseLongTerm: significantChanges.includes(
      "long_term_increase_in_expenses"
    ),
    incomeDecreaseShortTerm: significantChanges.includes(
      "short_term_decrease_in_income"
    ),
    incomeDecreaseLongTerm: significantChanges.includes(
      "long_term_decrease_in_income"
    ),
  };
};

export const getAnnualIncome = (income: string, frequency: string) => {
  if (frequency === PaymentFrequency.ANNUAL) {
    return +income;
  } else if (frequency === PaymentFrequency.MONTHLY) {
    return +income * 12;
  } else if (frequency === PaymentFrequency.FORTNIGHTLY) {
    return +income * 26;
  } else if (frequency === PaymentFrequency.WEEKLY) {
    return +income * 52;
  } else {
    return +income;
  }
};

export const trimDecimals = (value: number) => {
  return +value.toFixed(2);
};

export const mapToLoanApplicationRequest = (
  application: Application
): DashAPI.LoanApplicationModel => {
  const employmentDates = getStartAndEndDates(application, "employment");
  const addressDates = getStartAndEndDates(application, "address");
  const isAssetKnown = isValueYes(application.assetFound);

  return {
    loanAmount: +application.loanAmount,
    loanId: +application.loanId,
    loanPurpose: application.loanPurpose,
    loanTerm: +application.loanTerm * 12,
    otherDetail: null,
    applicants: [
      {
        firstName: application.firstName,
        middleName: application.middleName,
        lastName: application.lastName,
        doB: application.dateOfBirth.replace(/ /g, ""),
        householdDetails: {
          maritalStatus: application.maritalStatus,
          dependents: application.numberOfFinancialDependents
            ? +application.numberOfFinancialDependents
            : 0,
        },
        totalIncomeBeforeTax: trimDecimals(+application.incomeAmount),
        employments: [
          {
            employmentStatus: application.employmentStatus,
            years: +application.yearsAtEmployer,
            months: +application.monthsAtEmployer,
            startDate: employmentDates.currentStartDate,
            endDate: null,
            industry: application.employmentIndustry,
            employerName: application.employerName,
            employerMobile: application.employmentContactNumber.replace(
              / /g,
              ""
            ),
            incomeAmount: trimDecimals(+application.incomeAmount),
            incomeFrequency: application.incomeFrequency,
            occupation: application.occupation,
            isCurrent: true,
          },
          ...(hasPreviousEmployer(application)
            ? [
                {
                  employmentStatus: application.previousEmploymentStatus,
                  years: +application.yearsAtPreviousEmployer,
                  months: +application.monthsAtPreviousEmployer,
                  startDate: employmentDates.previousStartDate,
                  endDate: employmentDates.currentStartDate,
                  employerName: application.previousEmployerName,
                  employerMobile:
                    application.previousEmploymentContactNumber.replace(
                      / /g,
                      ""
                    ),
                  occupation: application.previousOccupation,
                  isCurrent: false,
                },
              ]
            : []),
        ],
        identities: {
          driverLicence: hasDriverLicence(application)
            ? {
                number: application.driverLicenceNumber,
                cardNumber: application.driverLicenceCardNumber,
                expiryDate: application.driversLicenceExpiry.replace(/ /g, ""),
                state: application.driversLicenceState,
              }
            : null,
          medicare: hasMedicare(application)
            ? {
                number: application.medicareCardNumber,
                cardColour: application.medicareColour,
                referenceNumber: application.medicareReferenceNumber,
                middleNameInitial: getStringOrNull(application.medicareInitial),
                expiryDate: application.medicareExpiry.replace(/ /g, ""),
              }
            : null,
          passport: hasPassport(application)
            ? {
                number: application.passportNumber,
                country: application.passportCountry,
              }
            : null,
        },
        contact: {
          mobile: application.mobile.replace(/ /g, ""),
          email: application.email,
        },
        addresses: [
          {
            unit: application.addressUnit,
            streetNumber: application.addressStreetNumber,
            streetName: application.addressStreetName,
            streetType: application.addressStreetType,
            suburb: application.addressSuburb,
            state: application.addressState.toLowerCase(),
            country: "australia",
            postcode: application.addressPostcode,
            isPrimary: true,
            years: +application.yearsAtAddress,
            months: +application.monthsAtAddress,
            startDate: addressDates.currentStartDate,
            endDate: null,
            residencyStatus: application.livingSituationAtAddress,
            totalRentalAmount: trimDecimals(+application.totalRentalAmount),
            frequency: application.rentalAmountFrequency,
            totalRentAmountShared: application.rentShared.length > 0,
            propertyContactName: application.propertyOwnerManagerName,
            propertyContactNumber:
              application.propertyOwnerManagerPhone.replace(/ /g, ""),
            mortgageFinanceCompanyName: application.mortgageFinanceCompany,
            mortgageBalance: trimDecimals(+application.mortgageBalance),
            mortgageRepaymentAmount: trimDecimals(
              +application.mortgageRepaymentAmount
            ),
            mortgageFrequency: application.mortgageRepaymentFrequency,
            isJointMortgage: isValueYes(application.hasCoBorrower),
          },
          ...(hasSecondaryAddress(application)
            ? [
                {
                  unit: application.previousAddressUnit,
                  streetNumber: application.previousAddressStreetNumber,
                  streetName: application.previousAddressStreetName,
                  streetType: application.previousAddressStreetType,
                  suburb: application.previousAddressSuburb,
                  state: application.previousAddressState.toLowerCase(),
                  country: "australia",
                  postcode: application.previousAddressPostcode,
                  isPrimary: false,
                  years: +application.yearsAtPreviousAddress,
                  months: +application.monthsAtPreviousAddress,
                  startDate: addressDates.previousStartDate,
                  endDate: addressDates.currentStartDate,
                },
              ]
            : []),
        ],
        investmentProperty: {
          ownsInvestmentProperty: isValueYes(
            application.ownsInvestmentProperty
          ),
          investmentPropertyMortgaged: isValueYes(
            application.investmentPropertyMortgaged
          ),
        },
        financialDetails: {
          otherIncomes: getOtherIncomesFromForm(application),
          debts: getDebtsFromForm(application),
          expenses: {
            groceries: trimDecimals(+application.groceries),
            groceriesFrequency: "monthly",
            clothing: trimDecimals(+application.clothing),
            clothingFrequency: "monthly",
            councilRates: trimDecimals(+application.council_rates),
            councilRatesFrequency: "monthly",
            carAndMotorbikeRegistration: trimDecimals(
              +application.car_registration
            ),
            carAndMotorbikeRegistrationFrequency: "monthly",
            petrolAndMaintenance: trimDecimals(+application.petrol),
            petrolAndMaintenanceFrequency: "monthly",
            carAndHomeInsurance: trimDecimals(+application.car_home_insurance),
            carAndHomeInsuranceFrequency: "monthly",
            utilities: trimDecimals(+application.utilities),
            utilitiesFrequency: "monthly",
            communications: trimDecimals(+application.communication),
            communicationsFrequency: "monthly",
            transportation: trimDecimals(+application.transportation),
            transportationFrequency: "monthly",
            entertainment: trimDecimals(+application.entertainment),
            entertainmentFrequency: "monthly",
            other: trimDecimals(+application.other_general_expenses),
            otherFrequency: "monthly",
            healthInsurance: trimDecimals(+application.health_insurance),
            healthInsuranceFrequency: "monthly",
            privateEducation: trimDecimals(+application.education),
            privateEducationFrequency: "monthly",
            secondMotorVehicle: trimDecimals(
              +application.seconds_motor_vehicle
            ),
            secondMotorVehicleFrequency: "monthly",
          },
          ...(hasPartner(application.maritalStatus) && {
            partnerTotalAnnualIncome: trimDecimals(
              +application.partnerIncomeAmount
            ),
          }),
        },
        significantPlannedChanges: getSignificantChangesFromForm(application),
      },
    ],
    declarations: {
      meetsEligibility: true,
      privacyConsent: true,
      accessSeekerCreditReportConsent: true,
      electronicCommunicationConsent: true,
    },
    requestSecuredRate: isValueTrue(application.requestSecuredRate),
    securedAssetDetails: {
      isKnown: isAssetKnown,
      yearOfManufacture: +application.assetYear,
      saleType: isAssetKnown ? application.assetSeller : null,
      approximateAge: +application.assetAgeEstimate,
    },
    googleClickId: null,
    page: application.page,
    offerId: application.offerId ? application.offerId : undefined,
    partnerCompanyId: application.partnerCompanyId
      ? application.partnerCompanyId
      : undefined,
    isJointLoan: false,
    bankStatementPreference: null,
    useESignatureContract: null,
    loanRequirementsAndObjectives: application.requirementsAndObjectives,
    allowBypassCreditSense: isValueTrue(application.allowBypassCreditSense),
  };
};

export const isValidLengthWithoutDecimals = (value: string, length: number) => {
  const lengthWithoutDecimals = length - 2;
  const splitValue = value.split(".");

  if (splitValue[0] && splitValue[0].length > lengthWithoutDecimals) {
    return false;
  } else {
    return true;
  }
};
