import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios.utils";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { SaveLoanApplication } from "../../types/loan-application";
import { quoteAmplitudeHelper } from "../../shared/analytics/analytics.utils";
import { ApplicationEvents } from "../../shared/analytics/analytics.consts";
import { getUser } from "../../auth/auth.utils";

export const returnLoanApplication = async (wisrApplicationId: string) => {
  return axios.get<DashAPI.LoanApplicationModel>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`
  );
};

export const returnLoanApplicationResponse = async (
  wisrApplicationId: string
) => {
  try {
    const response = await returnLoanApplication(wisrApplicationId);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.GET_LOAN_APPLICATION
      );
      return response.data;
    }
  } catch (err: any) {
    sendAmplitudeApiError(err);
    sendAppInsightsServerError(
      `error fetching loan application: ${err}`,
      AppInsightsErrorType.GET_LOAN_APPLICATION
    );
  }
};

export const fetchLoanApplication = createAsyncThunk(
  "loan-application/get",
  returnLoanApplicationResponse
);

export const putLoanApplication = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  return axios.put<any>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`,
    loanApplication,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const returnPutLoanApplicationResponse = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  if (!wisrApplicationId) return;

  try {
    const response = await putLoanApplication({
      wisrApplicationId,
      loanApplication,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.PUT_LOAN_APPLICATION
      );
      return response.data;
    }
  } catch (err: any) {
    sendAmplitudeApiError(err);
    sendAppInsightsServerError(
      `error saving loan application: ${err}`,
      AppInsightsErrorType.PUT_LOAN_APPLICATION
    );
  }
};

export const saveLoanApplication = createAsyncThunk(
  "loan-application/put",
  returnPutLoanApplicationResponse
);

export const postLoanApplication = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  return axios.post<any>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`,
    loanApplication,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const returnPostLoanApplicationResponse = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  if (!wisrApplicationId) return;

  try {
    const response = await postLoanApplication({
      wisrApplicationId,
      loanApplication,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.POST_LOAN_APPLICATION
      );
      return response.data;
    }
  } catch (err: any) {
    sendAmplitudeApiError(err);
    sendAppInsightsServerError(
      `error submitting loan application: ${err}`,
      AppInsightsErrorType.POST_LOAN_APPLICATION
    );
    if (err.response?.data?.errorFields) return err.response.data;
  }
};

export const submitLoanApplication = createAsyncThunk(
  "loan-application/post",
  returnPostLoanApplicationResponse
);

const sendAmplitudeApiError = (err: any) => {
  const user = getUser();

  quoteAmplitudeHelper.sendEvent(ApplicationEvents.API_ERROR, {
    ...(err && {
      Message: err.message,
      Stack: err.stack,
      ...(err.config && {
        "Request url": err.config.url,
        Method: err.config.method,
      }),
    }),
    ...(user?.expires_at && {
      "Token expiry": user.expires_at,
    }),
    Page: window.location.href,
  });
};
