/* istanbul ignore file */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { nanoid } from "nanoid";
import { User } from "oidc-client";
import AuthService from "../auth/login/login-auth.service";
import { AppInsightsError } from "../types/analytics";
import { appInsights } from "./insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  SERVER_ERROR,
} from "./insights/app-insights.consts";
import { createAppInsightsEvent } from "./insights/app-insights.utils";

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers.CorrelationID = nanoid(10);
    }
    await AuthService.getUser()?.then((user: User | null) => {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${user?.access_token || ""}`;
      }
    });
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const appInsightsError: AppInsightsError = {
      category: SERVER_ERROR,
      info: error.stack || "Unknown network error",
      type: "apiError",
    };
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(appInsightsError)
    );
    throw error;
  }
);

export default axios;
