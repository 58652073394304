import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import {
  GetRateEstimateResponse,
  ReturnRateEstimate,
  SubmitRateEstimateResponse,
} from "../../types/rate-estimate";

export const returnRateEstimate = async ({
  wisrApplicationId,
  partnerCompanyId,
}: ReturnRateEstimate) => {
  const queryParams = partnerCompanyId
    ? `?partnerCompanyId=${partnerCompanyId}`
    : "";
  return axios.get<GetRateEstimateResponse>(
    `${DASH_API}/RateEstimate/${wisrApplicationId}${queryParams}`
  );
};

export const returnRateEstimateResponse = async ({
  wisrApplicationId,
  partnerCompanyId,
}: ReturnRateEstimate) => {
  try {
    const response = await returnRateEstimate({
      wisrApplicationId,
      partnerCompanyId,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.RATE_ESTIMATE
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching rate estimate: ${err}`,
      AppInsightsErrorType.RATE_ESTIMATE
    );
  }
};

export const fetchRateEstimate = createAsyncThunk(
  "rate-estimate/get",
  returnRateEstimateResponse
);

export const postRateEstimate = async (
  rateEstimateRequest: DashAPI.SubmitRateEstimateRequest
) => {
  return axios.post<SubmitRateEstimateResponse>(
    `${DASH_API}/RateEstimate`,
    rateEstimateRequest,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const returnPostRateEstimateResponse = async (
  rateEstimateRequest: DashAPI.SubmitRateEstimateRequest
) => {
  try {
    const response = await postRateEstimate(rateEstimateRequest);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.POST_RATE_ESTIMATE
      );
      return response.data;
    }
  } catch (err: any) {
    sendAppInsightsServerError(
      `error submitting rate estimate: ${err}`,
      AppInsightsErrorType.POST_RATE_ESTIMATE
    );
    if (err.response?.data?.errorFields) return err.response.data;
  }
};

export const submitRateEstimate = createAsyncThunk(
  "rate-estimate/post",
  returnPostRateEstimateResponse
);
