import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import {
  EstimateAccept,
  EstimateAcceptRequest,
} from "../../types/estimate-accept";
import { ESTIMATE_ACCEPT_KEY } from "./estimate-accept.consts";

export const returnEstimateAccept = async (quoteId: string, term: number) => {
  return axios.post<EstimateAccept>(
    `${DASH_API}/RateEstimate/accept`,
    {
      quoteId,
      term,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-key": ESTIMATE_ACCEPT_KEY,
      },
    }
  );
};

export const returnEstimateAcceptResponse = async ({
  quoteId,
  term,
}: EstimateAcceptRequest) => {
  try {
    const response = await returnEstimateAccept(quoteId, term);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.ESTIMATE_ACCEPT
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching estimate accept: ${err}`,
      AppInsightsErrorType.ESTIMATE_ACCEPT
    );
  }
};

export const fetchEstimateAccept = createAsyncThunk(
  "estimate-accept/get",
  returnEstimateAcceptResponse
);
