import {
  isDefined,
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosError } from "axios";
import { navigate } from "gatsby";
import { Epic, ofType } from "redux-observable";
import { concat, defer, from, iif, Observable, of } from "rxjs";
import {
  catchError,
  concatMap,
  delay,
  map,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import axios from "../shared/axios.utils";
import { isBrowserMobile } from "../shared/browser/browser.utils";
import { OCR_API } from "../shared/url.consts";
import { AppAction, AppStore } from "../store";
import { OcrApiTransactionSuccess } from "../types/ocr";
import {
  OcrActionTypes,
  ocrCreateFailAction,
  ocrCreateSuccessAction,
  ocrGetAction,
  ocrGetFailedAction,
  ocrGetSuccessAction,
  ocrIncrementAttemptAction,
} from "./ocr.actions";
import { CREATE_OCR_LOADING, GET_OCR_LOADING } from "./ocr.constants";

const createOcrError =
  "Unable to start verification, please try again in a few minutes";
const ATTEMPT_INTERVAL = 20000; // 20 seconds
const MINIMUM_ATTEMPTS = 2; // 40 seconds
const MAXIMUM_ATTEMPTS = 30; // 5 minutes

const createOcrTransactionRequest = (
  mobile: string,
  wisrCustomerId: string
): Observable<OcrApiTransactionSuccess> => {
  return from(
    axios.post<OcrApiTransactionSuccess>(`${OCR_API}/submitmobile`, {
      mobile,
      wisrCustomerId,
      redirect: isBrowserMobile() ? "/credit/verification-success" : "",
    })
  ).pipe(map((res) => res.data));
};

export const createOcrTransaction: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(OcrActionTypes.CREATE),
    concatMap((action) =>
      concat(
        of(loadingStartAction(CREATE_OCR_LOADING)),
        createOcrTransactionRequest(action.mobile, action.wisrCustomerId).pipe(
          map((transaction) =>
            ocrCreateSuccessAction(
              transaction,
              action.mobile,
              action.wisrCustomerId
            )
          )
        ),
        of(loadingSuccessAction(CREATE_OCR_LOADING))
      ).pipe(
        catchError((e: AxiosError) =>
          concat(
            of(
              loadingFailAction(
                CREATE_OCR_LOADING,
                (e.response?.data as string) ?? createOcrError
              )
            ),
            of(
              ocrCreateFailAction(
                e.response?.data ?? {
                  message: createOcrError,
                }
              )
            )
          )
        )
      )
    )
  );

const getOcrTransactionResponse = (
  transactionId: string
): Observable<OcrApiTransactionSuccess> => {
  return from(
    axios.get<OcrApiTransactionSuccess>(`${OCR_API}/details/${transactionId}`)
  ).pipe(map((res) => res.data));
};

const handleOcrTransactionResponse = (
  transaction: OcrApiTransactionSuccess
) => {
  if (
    transaction.status.includes("Completed") &&
    isDefined(transaction.customerData)
  ) {
    navigate("/credit/create-profile");
    return ocrGetSuccessAction(
      transaction.customerData,
      transaction.overAllResult
    );
  }
  if (transaction.status.includes("Expired")) {
    return ocrGetFailedAction({
      message: "Verification has expired. Please try again.",
    });
  }
  return ocrIncrementAttemptAction();
};

export const getOcrTransaction: Epic<AppAction, AppAction, AppStore> = (
  actions,
  AppState
) =>
  actions.pipe(
    ofType(OcrActionTypes.GET),
    withLatestFrom(AppState),
    concatMap(([, state]) =>
      iif(
        () =>
          state.ocrState.transactionAttemptCount > MINIMUM_ATTEMPTS &&
          state.ocrState.transactionAttemptCount <= MAXIMUM_ATTEMPTS,
        defer(() =>
          concat(
            of(loadingStartAction(GET_OCR_LOADING)),
            getOcrTransactionResponse(state.ocrState.transactionId || "").pipe(
              map((transaction) => handleOcrTransactionResponse(transaction))
            ),
            of(loadingSuccessAction(GET_OCR_LOADING))
          ).pipe(
            catchError((e: AxiosError) =>
              concat(
                of(
                  loadingFailAction(
                    GET_OCR_LOADING,
                    e.response?.data
                      ? (e.response.data as AxiosError).message
                      : "Could not get Ocr transaction results."
                  )
                ),
                of(
                  ocrGetFailedAction(
                    e.response?.data ?? {
                      message: "Could not get Ocr transaction results.",
                    }
                  )
                )
              )
            )
          )
        ),
        defer(() =>
          of(
            state.ocrState.transactionAttemptCount > MAXIMUM_ATTEMPTS
              ? ocrGetFailedAction({
                  message:
                    "We weren't able to verify you. Please attempt the verification again.",
                })
              : ocrIncrementAttemptAction()
          ).pipe(takeUntil(actions.pipe(ofType(OcrActionTypes.CLEAR))))
        )
      )
    )
  );

export const ocrIncrementAttempt: Epic<AppAction, AppAction, AppStore> = (
  actions,
  state
) =>
  actions.pipe(
    ofType(OcrActionTypes.INCREMENT_ATTEMPT),
    withLatestFrom(state),
    concatMap(() =>
      concat(
        of(ocrGetAction())
          .pipe(delay(ATTEMPT_INTERVAL))
          .pipe(takeUntil(actions.pipe(ofType(OcrActionTypes.CLEAR))))
      )
    )
  );
